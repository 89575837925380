module.exports = {
  development: false,
  wrapper: {
    url: "https://bbaccelerate.com/api/",
  },
  api: {
    url: "https://bbaccelerate.com/api/",
  },
  ui: {
    url: "https://bbaccelerate.com",
  },
  desktop: {
    settings: {
      font: "Roboto",
      theme: "ArrowHeadTheme",
    },
  },
};
